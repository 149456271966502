import React from "react";
import Layout from "../components/layout";
import Logo from "../components/Logo";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Web development Antwerpen`, `studio koko`, `react`]}
        title="Home"
      />
      <header className="flex h-screen items-center">
        <Logo />
      </header>

      <section className="text-center">
        <div
          className="flex flex-col bg-white px-8 py-6 max-w-sm mx-auto mt-16 koko-details"
        >
          <div className="flex flex-col justify-between items-center">
            <div className="flex items-center">
              <a className="text-md mx-3" href="#">
                studio koko
              </a>
            </div>
            <div className="flex items-center mb-1">
              <a className="text-sm mx-3" href="#">
                web &amp; app development
              </a>
            </div>
            <div>
              <span className="font-light text-sm text-gray-600">
                <a href="mailto:joris@studiokoko.be">joris@studiokoko.be</a>
              </span>
            </div>
            <div>
              <span className="font-light text-sm text-gray-600">
                <a href="tel:0484699704">0484699704</a>
              </span>
            </div>
            <div>
              <span className="font-light text-sm text-gray-600">
                Thaliastraat 23, 2600 Berchem
              </span>
            </div>
            <div>
              <span className="font-light text-sm text-gray-600">
              BE 0712728581
              </span>
            </div>


          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
